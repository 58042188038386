import React, { useState } from 'react'
import { Input } from './form/InputField'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import ChevronIcon from '../assets/custom-icons/chevron.svg'

const DropdownClickHandlerWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
`

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  list-style: none;
  background: transparent;
  min-width: 100%;
  margin: 0.125rem 0 0;
  z-index: 1000;
  padding-top: 13px;
  height: 300px;
  overflow: auto;
  scroll-behavior: smooth;
  @media (max-width: 414px) {
    height: 350px;
  }
`

const DropdownItem = styled.li`
  background-color: white;
  border-top: 1px solid #e6e6e6;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 10px;
  padding-right: 10px;
`

const DropdownItemButton = styled.button`
  display: block;
  width: 100%;
  padding: 0.25rem 0.625rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.MAN_bold};
  font-size: 16px;
  border-radius: 0.25rem;
  text-align: left;
  color: ${(props) => props.theme.color.manAnthracite};

  &:hover {
    color: ${(props) => props.theme.color.manRed};
  }
`

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.color.manRed};
  font-size: 18px;
  padding: 0;
  transition: 0.3s ease-in color;
  pointer-events: none;
  --rotation: ${(props) => (props.$direction === 'top' ? '180deg' : '0deg')};

  svg {
    fill: currentColor;
    transform: rotate(var(--rotation));
  }
`

const DropdownButton = styled(Input)`
  background: transparent;
  border: 0;
  padding-right: 17px;
  cursor: pointer;
  display: block;
  padding-top: 0;
  padding-bottom: 0;

  > span {
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`

const DropdownValue = styled.div`
  margin-right: 0.2rem;
  display: block;
  text-align: left;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.MAN_bold};
  color: ${(props) => props.theme.color.manAnthracite};
  font-size: 16px;
  line-height: 16px;
`

const Placeholder = styled.span`
  display: block;
  text-align: center;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.MAN_light};
  position: absolute;
  pointer-events: none;
  cursor: pointer;
  color: ${(props) => props.theme.color.anthracite800};
`

export const HeaderDropdown = ({
  value,
  options,
  onChange,
  onBlur,
  onFocus,
}) => {
  const [hasInteracted, setHasInteracted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const closeDropdown = () => {
    if (hasInteracted) {
      typeof onBlur === 'function' && onBlur()
    }

    setIsOpen(false)
  }

  const openDropdown = () => {
    typeof onFocus === 'function' && onFocus()
    setHasInteracted(true)
    setIsOpen(true)
  }

  const handleButtonClick = () => (isOpen ? closeDropdown() : openDropdown())

  const dropdownList = (
    <DropdownList>
      {options.map((option) => (
        <DropdownItem key={option.value}>
          <DropdownItemButton
            onClick={() => {
              onChange(option.value)
              setIsOpen(false)
            }}
          >
            <span>{option.label}</span>
          </DropdownItemButton>
        </DropdownItem>
      ))}
    </DropdownList>
  )

  return (
    <DropdownClickHandlerWrapper>
      <OutsideClickHandler onOutsideClick={closeDropdown}>
        <DropdownWrapper>
          <div style={{ position: 'relative' }}>
            <DropdownButton
              as='button'
              type='button'
              onClick={handleButtonClick}
            >
              <DropdownValue>
                {options
                  .find((option) => option.value === value)
                  ?.value.toUpperCase() || ''}
              </DropdownValue>
              <IconWrapper $direction={isOpen ? 'top' : 'bottom'}>
                <ChevronIcon />
              </IconWrapper>
            </DropdownButton>
          </div>
          {isOpen && dropdownList}
        </DropdownWrapper>
      </OutsideClickHandler>
    </DropdownClickHandlerWrapper>
  )
}
