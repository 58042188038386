import React from 'react'
import { Helmet } from 'react-helmet'
import { GlobalStyleWrapper } from '../components/GlobalStyleWrapper'
import { ManFooter } from '../components/ManFooter'
import { ManHeader } from '../components/ManHeader'
import OGIMage from '../images/og-image-xmas.jpg'

export const GlobalLayout = ({ children, headerWithSeperator }) => {
  return (
    <GlobalStyleWrapper>
      <Helmet>
        <meta
          property='og:image'
          content={'https://christmas-card.man.eu' + OGIMage}
        />
      </Helmet>
      <ManHeader withSeperator={headerWithSeperator} />
      {children}
      <ManFooter />
    </GlobalStyleWrapper>
  )
}
