export const track = (obj) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(obj)
}

/**
 *
 * @param {'Website Modules' | 'Navigation' | 'Conversions'} eventCategory
 * @param {string} eventLabel
 * @param {number=} eventValue
 * @param {string} eventAction
 * @returns {boolean}
 */
export const structuredTracking = ({
  eventCategory,
  eventLabel,
  eventAction,
  eventValue = 0,
}) => {
  return track({
    event: 'gaEvent',
    eventCategory,
    eventLabel,
    eventValue,
    eventAction,
  })
}

export const gtmTrackPageView = () => {
  track({
    event: 'pageview',
    page_path: window.location.pathname,
    page_title: document.title,
  })
}
