export const GlobalData = {
  languages: [
    { value: 'de', label: 'DE – Deutsch' },
    { value: 'en', label: 'EN – English' },
    { value: 'fr', label: 'FR – French' },
    { value: 'it', label: 'IT – Italian' },
    { value: 'es', label: 'ES – Spanish' },
    { value: 'pt', label: 'PT – Portuguese' },
    { value: 'bg', label: 'BG – Bulgarian' },
    { value: 'da', label: 'DA – Danish' },
    { value: 'fi', label: 'FI – Finnish' },
    { value: 'fl', label: 'FL – Flemish' },
    { value: 'el', label: 'EL – Greek' },
    { value: 'hr', label: 'HR – Croatian' },
    { value: 'nl', label: 'NL – Dutch' },
    { value: 'pl', label: 'PL – Polish' },
    { value: 'ru', label: 'RU – Russian' },
    { value: 'sv', label: 'SV – Swedish' },
    { value: 'sk', label: 'SK – Slovak' },
    { value: 'sl', label: 'SL – Slovenian' },
    { value: 'cs', label: 'CS – Czech' },
    { value: 'tr', label: 'TR – Turkish' },
    { value: 'hu', label: 'HU – Hungarian' },
    { value: 'uk', label: 'UK – Ukrainian' },
    { value: 'no', label: 'NO – Norwegian' },
  ],
  links: {
    dataPrivacy:
      'https://www.man.eu/de/en/general/data-protection-routing.html',
    imprint: 'https://www.man.eu/de/en/general/impressum.html',
    agb: 'https://www.man.eu/de/de/general/agb_s.html',
    cookies: 'https://www.man.eu/de/de/general/cookies.html',
    manTruckAndBus: 'https://www.man.eu/',
  },
  branches: [
    {
      value: 'Landw. Betriebe (inkl. Fischerei)',
      label: 'Landw. Betriebe (inkl. Fischerei)',
    },
    {
      value: 'Maurer',
      label: 'Maurer',
    },
    {
      value: 'Hochbau allgemein & sonstige',
      label: 'Hochbau allgemein & sonstige',
    },
    {
      value: 'Schreinerei, Tischlerei und Glaserei',
      label: 'Schreinerei, Tischlerei und Glaserei',
    },
    {
      value: 'Zimmerei und Dachdecker',
      label: 'Zimmerei und Dachdecker',
    },
    {
      value: 'Tiefbau allgemein & sonstige',
      label: 'Tiefbau allgemein & sonstige',
    },
    {
      value: 'Garten-/Landschaftsbau und Steinmetz',
      label: 'Garten-/Landschaftsbau und Steinmetz',
    },
    {
      value: 'Bergbau',
      label: 'Bergbau',
    },
    {
      value: 'Straßenbau',
      label: 'Straßenbau',
    },
    {
      value: 'Gerüstbau',
      label: 'Gerüstbau',
    },
    {
      value: 'Transportmischer und Betonpumpen',
      label: 'Transportmischer und Betonpumpen',
    },
    {
      value: 'Holzverarbeitung und Holztransporte',
      label: 'Holzverarbeitung und Holztransporte',
    },
    {
      value: 'andere Branche',
      label: 'andere Branche',
    },
  ],
  countries: [
    {
      value: 'Deutschland',
      label: 'Deutschland',
    },
  ],
}

export const ErrorMessages = {
  firstName: 'Bitte geben Sie Ihren Vornamen ein.',
  lastName: 'Bitte geben Sie Ihren Nachnamen ein.',
  salutation: 'Bitte geben Sie Ihre Anrede ein.',
  street: 'Bitte geben Sie Ihre Straße ein.',
  houseNumber: 'Bitte geben Sie Ihre Hausnummer ein.',
  zip: 'Bitte geben Sie Ihre PLZ ein.',
  city: 'Bitte geben Sie eine Stadt ein.',
  country: 'Bitte geben Sie ein Land ein.',
  phone: 'Bitte geben Sie Ihre Telefonnummer ein.',
  time: 'Bitte wählen Sie eine gewünschte Uhrzeit.',
  message: 'Bitte geben Sie Ihre Nachricht ein.',
  email: {
    format: 'Bitte geben Sie eine valide E-Mail Adresse ein.',
    empty: 'Bitte geben Sie Ihre E-Mail Adresse ein.',
  },
  legalConsent: 'Bitte bestätigen Sie die Zustimmung und Kenntnisnahme.',
}

export const Labels = {
  legalConsent: `Zustimmung der <a href="${GlobalData.links.agb}" target="_blank">AGBs</a> und Kenntnisnahme der <a href="${GlobalData.links.dataPrivacy}" target="_blank">Hinweise zum Datenschutz</a>*`,
  marketing: 'Einwilligung der Datenverarbeitung für Marketing Zwecke',
}
