import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
  outline: 0;
  transition: color 0.2s ease;
  text-decoration: none;
  background-color: transparent;
  color: ${(props) => props.theme.color.manAnthracite};
  &:hover {
    color: ${(props) => props.theme.color.manRed};
  }
`

export const ManLink = ({ href, text, target }) => {
  return (
    <Link href={href} target={target || '_self'} rel='noopener noreferrer'>
      {text}
    </Link>
  )
}
