import React from 'react'
import { Helmet } from 'react-helmet'

export function PiwikConnector() {
  return (
      <Helmet>
          <script
              type='text/javascript'
              src={process.env.GATSBY_PIWIK_URL}
          ></script>
          <script
              src='https://cdn.cookielaw.org/consent/ff64ab59-02be-4902-ba79-11da94501635/otSDKStub.js'
              type='text/javascript'
              charset='UTF-8'
              data-domain-script={process.env.GATSBY_PIWIK_DATA_DOMAIN}
          ></script>
          <script type='text/javascript'>
              {`
                function OptanonWrapper() {}
              `}
          </script>
      </Helmet>
  )
}
