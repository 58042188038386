// Setup breakpoints here as well: ../style/grid.scss#$grid-breakpoints
export const theme = {
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1480px',
  },
  color: {
    manAnthracite: '#303c49',
    anthracite200: '#ABB6C3',
    anthracite800: '#303c49',
    anthracite900: 'rgba(30, 40, 50, 1)',
    manAnthracite700: '#3F4E5E',
    manAnthracite80: '#59636d',
    manAnthracite60: '#838a92',
    manAnthracite50: '#eaedf3',
    manAnthracite40: '#acb1b6',
    manAnthracite20: '#d6d8db',
    manAnthracite10: '#f4f5f6',
    anthracite400: 'rgba(115, 132, 151, 1)',
    manRed: 'rgba(228, 0, 69, 1)',
    manRed500: 'rgba(228, 0, 69, 1)',
    manRed600: 'rgba(212, 0, 67, 1)',
    manRed700: 'rgba(192, 0, 65, 1)',
    manSilver: '#afafaf',
    silver100: 'rgba(240, 240, 240, 1)',
    silver300: 'rgba(211, 211, 211, 1)',
    silver400: 'rgba(175, 175, 175, 1)',
    silver700: 'rgba(175, 175, 175, 1)',
    manGreen: '#91b900',
    manBlue: '#4b96d2',
    manYellow: '#ffcd00',
    success800: 'rgba(85, 131, 0, 1)',
    warning900: 'rgba(255, 109, 0, 1)',
    danger800: 'rgba(172, 0, 63, 1)',
  },
  fonts: {
    MAN_light:
      'MAN-light,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    MAN_regular:
      'MAN-regular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    MAN_bold_condensed:
      'MAN-bold-condensed,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    MAN_bold:
      'MAN-bold,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    MAN_light_italic:
      'MAN-light-italic,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
}
