import React, { useState } from 'react'
import styled from 'styled-components'

const DEFAULT_TRANSITION = '0.3s ease-in'

export const colorBySeverity = {
  success: 'success800',
  warning: 'warning900',
  error: 'danger800',
  help: 'anthracite400',
}

export const InputFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  --severity: ${(props) =>
    props.$isInFocus
      ? props.theme.color.anthracite900
      : props.$isInputFilled
      ? props.theme.color.anthracite800
      : props.theme.color.anthracite400};

  // Needs to be an property value. A change in $severity wasn't registered, so the value did not change
  ${(props) => {
    if (props.$severity)
      return `--severity: ${
        props.theme.color[colorBySeverity[props.$severity]]
      }`
  }}
`

export const InnerWrapper = styled.div`
  position: relative;
`

export const Label = styled.label`
  display: inline-block;
  font-family: ${(props) => props.theme.fonts.MAN_light};
  font-size: 12px;
  line-height: 24px;
  z-index: 1;
  transition: color ${DEFAULT_TRANSITION}, transform ${DEFAULT_TRANSITION};
  color: var(--severity);

  transform-origin: bottom left;
  transform: ${(props) =>
    props.$isInputFilled
      ? 'scale(1) translateY(0px)'
      : 'scale(1.3) translateY(2.1em)'};

  pointer-events: ${(props) => (props.$isInputFilled ? 'all' : 'none')};
`

export const Input = styled.input`
  width: 100%;
  border: none;
  font-family: ${(props) => props.theme.fonts.MAN_light};
  font-size: 16px;
  line-height: 24px;
  padding: 3px 0;
  background: transparent;
  color: black;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`

export const InputBorder = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transition: ${DEFAULT_TRANSITION};

  height: ${(props) => (props.$isInFocus ? '2px' : '1px')};

  background-color: var(--severity);

  transition: background-color ${DEFAULT_TRANSITION};
`

export const InputField = ({
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  severity,
  iconSlot,
  ...props
}) => {
  const [inFocus, setInFocus] = useState(false)

  const isInputFilled = value.length > 0

  return (
    <InputFieldWrapper
      $severity={severity}
      $isInputFilled={isInputFilled}
      $isInFocus={inFocus}
    >
      {label && (
        <Label labelFor={name} $isInputFilled={isInputFilled}>
          {label}
        </Label>
      )}
      <InnerWrapper>
        <Input
          value={value}
          name={name}
          onChange={onChange}
          onFocus={(evt) => {
            typeof onFocus === 'function' && onFocus(evt)
            setInFocus(true)
          }}
          onBlur={(evt) => {
            typeof onBlur === 'function' && onBlur(evt)
            setInFocus(false)
          }}
          {...props}
        />
        {iconSlot && iconSlot}
        <InputBorder $isInFocus={inFocus} />
      </InnerWrapper>
    </InputFieldWrapper>
  )
}
