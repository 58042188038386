import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'
import logo_svg from '../assets/images/man_logo_batch.svg'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { structuredTracking } from '../service/tracking'
import { HeaderDropdown } from './HeaderDropdown'
import { GlobalData } from '../data/GlobalData'

export const headerHeight = { desktop: '78px', mobile: '58px' }

const Header = styled.div`
  height: 58px;
  background-color: #fff;
  z-index: 1030;
  position: relative;
  width: 100%;

  @supports (-webkit-touch-callout: none) {
    width: 100vw;
  }

  ${up('sm')} {
    height: 78px;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-right: var(--man-gutter-x);
  padding-left: var(--man-gutter-x);
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;

  ${down('xs')} {
    background-color: transparent;
    padding-left: 12px;
    padding-right: 12px;
  }
`

const HeaderContent = styled.div`
  position: relative;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;

  ${up('sm')} {
    padding-top: 0.55rem;
  }
`

const logoWidth = '9.0625rem'
const logoWidthMobile = '103.567px'

const Navbar = styled.div`
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  max-width: calc(100% - ${logoWidthMobile} - 1rem);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${up('sm')} {
    max-width: calc(100% - ${logoWidth} - 1rem);
  }
`

const NavbarItem = styled.div`
  text-transform: uppercase;
  line-height: 1rem;
  font-family: ${(props) => props.theme.fonts.MAN_bold_condensed};
  margin-right: 1rem;
`

const SeparatorWrapper = styled.div`
  width: 100%;
  display: block;
  position: absolute;
`

const Separator = styled.div`
  height: 2px;
  background-color: ${(props) => props.theme.color.manAnthracite50};
  content: '';
  display: block;
  width: calc(100% - ${logoWidth} - 1.5rem);
  position: absolute;
  left: 0;

  ${down('xs')} {
    bottom: 0px;
    width: calc(100% - ${logoWidthMobile} - 1rem);
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0.25rem;
  transition: all 0.2s ease;
  width: ${logoWidth};
  height: auto;
  z-index: 2;

  ${down('xs')} {
    width: auto;
    height: ${headerHeight.mobile};
    top: 0;
  }
`

const LogoImg = styled.img`
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;

  ${down('xs')} {
    height: 100%;
  }
`

const Item = styled.p`
  outline: 0;
  transition: color 0.2s ease;
  text-decoration: none;
  background-color: transparent;
  color: ${(props) => props.theme.color.manAnthracite};
`

const LanguageSwitcher = styled.div`
  display: flex;
`

const LanguageItem = styled.button`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  text-transform: uppercase;
  font-family: ${(props) =>
    props.$isActive ? props.theme.fonts.MAN_bold : props.theme.fonts.MAN_light};
  color: ${(props) => props.theme.color.manAnthracite};
  font-size: 1rem;
  cursor: pointer;
  line-height: 1;

  &:not(:last-child) {
    padding-right: 15px;

    &::after {
      content: '';
      position: absolute;
      right: 6.5px;
      height: 100%;
      width: 2px;
      background: ${(props) => props.theme.color.manAnthracite};
    }
  }
`

export const ManHeader = ({ withSeperator }) => {
  const ctx = useI18next()

  const changeLanguage = (value) => {
    if (
      window.location.pathname.endsWith('/') ||
      window.location.pathname === '/'
    ) {
      ctx.changeLanguage(value)
      return
    }
    console.log(window.location.search)
    const targetUrl = `${window.location.pathname.replace(
      ctx.language,
      value
    )}/${window.location.search ? `?${window.location.search}` : ''}`

    window.location.href = targetUrl
  }

  return (
    <Header id='man-header'>
      <Container>
        <HeaderContent>
          <Navbar>
            <NavbarItem>
              <Item>MAN TRUCK & BUS</Item>
            </NavbarItem>
            <LanguageSwitcher>
              <HeaderDropdown
                onChange={changeLanguage}
                options={GlobalData.languages}
                value={ctx.language}
              />
            </LanguageSwitcher>
          </Navbar>
          {withSeperator && (
            <SeparatorWrapper>
              <Separator />
            </SeparatorWrapper>
          )}

          <Link
            to='/'
            onClick={() => {
              structuredTracking({
                eventCategory: 'Navigation',
                eventAction: 'Header',
                eventLabel: `MAN Logo:/${ctx.language}`,
              })
            }}
          >
            <LogoWrapper>
              <LogoImg src={logo_svg} />
            </LogoWrapper>
          </Link>
        </HeaderContent>
      </Container>
    </Header>
  )
}
