import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const Copy = styled.span`
  font-size: 16px;

  ${up('md')} {
    font-size: 18px;
  }
  color: ${(props) =>
    props.color
      ? props.theme.color[props.color]
      : props.theme.color.manAnthracite};
`

export const ManCopy = ({ text, color, children, ...otherProps }) => {
  let props = {
    ...otherProps,
  }

  if (!children) {
    props.dangerouslySetInnerHTML = { __html: text }
  }

  return (
    <Copy {...props} color={color}>
      {children}
    </Copy>
  )
}
