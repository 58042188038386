import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Reset } from 'styled-reset'
import '../style/grid.scss'
import '../style/fonts.scss'
import '../style/base.scss'
import { theme } from './theme'

const GlobalStylesheet = createGlobalStyle`

  html,
  body {
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    --man-gutter-x: 7.5vw;
    
    margin: 0;
    font-family: ${theme.fonts.MAN_light};
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.color.manAnthracite};
    text-align: left;
    background-color: #fff;
  }

  *, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  p {
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b {
    font-weight: bold;
  }
`

export const GlobalStyleWrapper = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStylesheet />
      {children}
    </ThemeProvider>
  )
}
