import React from 'react'
import { Helmet } from 'react-helmet'

export function OneTrustConnector() {
  return (
    <Helmet>
      <script
        type='text/javascript'
        src='https://cdn.cookielaw.org/consent/ff64ab59-02be-4902-ba79-11da94501635/OtAutoBlock.js'
      ></script>
      <script
        src='https://cdn.cookielaw.org/consent/ff64ab59-02be-4902-ba79-11da94501635/otSDKStub.js'
        data-document-language='true'
        type='text/javascript'
        charset='UTF-8'
        data-domain-script='ff64ab59-02be-4902-ba79-11da94501635'
      ></script>
      <script type='text/javascript'>
        {`
          function OptanonWrapper() { 
          }
        `}
      </script>
    </Helmet>
  )
}
