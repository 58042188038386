import React from 'react'
import styled from 'styled-components'
import { up, down } from 'styled-breakpoints'

import { ManLink } from './ManLink'
import { ManCopy } from './ManCopy'

import logo_footer from '../assets/images/man_footer_logo.png'
import DecorationImage from '../assets/images/newDecoration.png'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Footer = styled.footer`
  position: relative;
  background: white;
  padding-top: 30px;
`

const FooterSection = styled.div`
  background-color: ${(props) => props.theme.color.manAnthracite10};
  margin: 0.125rem 0;
  padding-left: var(--man-gutter-x);
  padding-right: var(--man-gutter-x);

  line-height: 1.4285714286;
  font-size: 0.875rem;
  padding-top: 2rem;
  padding-bottom: 1rem;

  ${up('md')} {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
  }

  ${up('xl')} {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
`

const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`
const RowItem = styled.div`
  margin-right: 2rem;
  margin-bottom: 1rem;

  ${down('xs')} {
    width: 100%;
  }
`

const LogoImg = styled.img`
  width: 60px;
  height: 34px;
  border-style: none;
  margin-bottom: 1rem;
  margin-right: 1rem;
`

const LogoText = styled.span`
  font-size: 0.9375rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
`

const SubHeadline = styled.div`
  font-family: ${(props) => props.theme.fonts.MAN_regular};
  font-size: 1rem;
  line-height: 1.375;
  margin: 1.75rem 0 0.75rem;

  &:first-child {
    margin-top: 0;
  }

  ${up('md')} {
    line-height: 1.3888888889;
    font-size: 1.125rem;
    margin: 3rem 0 1rem;
  }
`

const Decoration = styled.div`
  height: 120px;
  background-image: url(${DecorationImage});
`

export const ManFooter = () => {
  const { t } = useTranslation()

  return (
    <Footer>
      <Decoration />
      <FooterSection>
        <ContentRow>
          <RowItem>
            <ManLink
              href={t('allLinks.impressum')}
              target='_blank'
              text={t('Impressum')}
            />
          </RowItem>
          <RowItem>
            <ManLink
              href={t('allLinks.dataProtectionLink')}
              target='_blank'
              text={t('Datenschutz')}
            />
          </RowItem>
          <RowItem>
            <ManLink
                href={t('allLinks.cookiesLink')}
                target='_blank'
                text='Cookies'
            />
          </RowItem>
          <RowItem>
            <ManLink
              href={t('allLinks.websiteManTruckAndBus')}
              target='_blank'
              text='MAN Truck & Bus'
            />
          </RowItem>
        </ContentRow>
      </FooterSection>
      <FooterSection>
        <ContentRow>
          <LogoImg src={logo_footer} />
          <LogoText>
            <ManCopy
              style={{ fontSize: '14px' }}
              text={'A member of TRATON GROUP.'}
            />
          </LogoText>
          <LogoText>
            <ManCopy
              style={{ fontSize: '14px' }}
              text={`© MAN ${new Date().getFullYear()}`}
            />
          </LogoText>
        </ContentRow>
      </FooterSection>
    </Footer>
  )
}
