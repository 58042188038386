import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import propTypes from 'prop-types'

const BaseHeadline = styled.h1`
  text-transform: uppercase;
  hyphens: auto;
`

const BoldHeadlineBase = styled(BaseHeadline)`
  font-family: ${(props) => props.theme.fonts.MAN_bold_condensed};
`

const CustomXXLHeadline = styled(BoldHeadlineBase)`
  font-size: 28px;
  line-height: 48px;

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 32px;
  }
  @media (min-width: 920px) {
    font-size: 72px;
    line-height: 79px;
  }
`

const XXLHeadline = styled(BoldHeadlineBase)`
  font-size: 43px;
  line-height: 48px;

  ${up('md')} {
    font-size: 72px;
    line-height: 79px;
  }
`

const XLHeadline = styled(BoldHeadlineBase)`
  font-size: 36px;
  line-height: 40px;

  ${up('md')} {
    font-size: 60px;
    line-height: 66px;
  }
`

const LHeadline = styled(BoldHeadlineBase)`
  font-size: 29px;
  line-height: 32px;

  ${up('md')} {
    font-size: 48px;
    line-height: 53px;
  }
`

const H1 = styled(BoldHeadlineBase)`
  font-size: 28px;
  line-height: 34px;

  ${up('md')} {
    font-size: 36px;
    line-height: 43px;
  }
`

const H2Bold = styled(BoldHeadlineBase)`
  font-size: 24px;
  line-height: 29px;

  ${up('md')} {
    font-size: 32px;
    line-height: 38px;
  }
`

const H2Regular = styled(H2Bold)`
  font-family: ${(props) => props.theme.fonts.MAN_regular};
`

export const H3 = styled(BoldHeadlineBase)`
  font-size: 20px;
  line-height: 24px;

  ${up('md')} {
    font-size: 28px;
    line-height: 34px;
  }
`

const H4Bold = styled(BoldHeadlineBase)`
  font-size: 18px;
  line-height: 22px;

  ${up('md')} {
    font-size: 24px;
    line-height: 29px;
  }
`

const H4Regular = styled(H4Bold)`
  font-family: ${(props) => props.theme.fonts.MAN_regular};
`

const H5 = styled(BoldHeadlineBase)`
  font-size: 16px;
  line-height: 20px;

  ${up('md')} {
    font-size: 20px;
    line-height: 24px;
  }
`

const H6 = styled(BoldHeadlineBase)`
  font-size: 14px;
  line-height: 18px;

  ${up('md')} {
    font-size: 18px;
    line-height: 22px;
  }
`

const SIZE_TO_COMPONENT_MAP = {
  xxlCustom: CustomXXLHeadline,
  xxl: XXLHeadline,
  xl: XLHeadline,
  l: LHeadline,
  h1: H1,
  'h2-bold': H2Bold,
  'h2-regular': H2Regular,
  h3: H3,
  'h4-bold': H4Bold,
  'h4-regular': H4Regular,
  h5: H5,
  h6: H6,
}

export const Headline = ({ size, as, children, ...props }) => {
  const Component = SIZE_TO_COMPONENT_MAP[size]
  const asComponent = size.startsWith('h') ? `h${size.charAt(1)}` : as

  return (
    <Component as={asComponent} {...props}>
      {children}
    </Component>
  )
}

Headline.propTypes = {
  size: propTypes.oneOf(Object.keys(SIZE_TO_COMPONENT_MAP)).isRequired,
  as: function (props) {
    if (!props.size.startsWith('h') && !props.as) {
      throw new Error(
        'Please pass an as prop for the size, e.g. h1, h2, p [...]'
      )
    }
  },
}
